<template>
<div class='yzg'>
  <YzgHeader></YzgHeader>
  <IndexContent></IndexContent>
  <YzgFooter></YzgFooter>
</div>
</template>

<script>
import YzgHeader from "@/components/YzgHeader"
import YzgFooter from "@/components/YzgFooter"
import IndexContent from "@/components/IndexContent"
export default {
components: {
  YzgHeader,
  YzgFooter,
  IndexContent
},
data() {
return {
};
},
methods: {

},
computed: {},
props:{},
watch: {},
created() {

},
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("../assets/index.css");
//.yzg {
//  -webkit-filter: grayscale(100%);
//  -moz-filter: grayscale(100%);
//  -ms-filter: grayscale(100%);
//  -o-filter: grayscale(100%);
//  filter: grayscale(100%);
//  filter: gray;
//  padding: 0;
//  margin: 0;
//}

</style>
